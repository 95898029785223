import React, { useState, useEffect, useRef } from 'react';
import { Box, Grid } from '@mui/material';
import ReviewForm from './ReviewForm';
import { Header, post } from '../components';
import AccessManager from '../authentication/AccessManager';
import { useNavigate } from 'react-router-dom';


const Body = React.memo((props) => {
  const [ taskInput, setTaskInputs ] = useState({});
  const [ taskId, setTaskId ] = useState({});

  useEffect(()=>{ 
    if(props.task !== null) {
      setTaskInputs(props.task.predictions);
      setTaskId(props.task.task_id);
    };
  },[props])

  return (
    <Box sx={{
      display: 'grid',
      gridAutoRows: '80%',
      gap: 1,
    }}>
      { (props.task !== null) ?
        <Grid container>
          <Grid item xs={9}>
            <Box
              component='img'
              sx={{
                width: 1280,
                height: 720,
                minWidth: 1280,
                minHeight: 720,
                border: 5,
                borderColor: 'primary.main'
              }}
              alt="Too late to review the vitals. It's more than 10 hours."
              src={props.task.image_path}
            />
          </Grid>
          <Grid item xs={3}>
            <ReviewForm 
              inputs={{...taskInput}} 
              task_id={taskId}
              isDisabled={props.isDisabled}
              updateTask={props.onSubmit} />
          </Grid>
        </Grid>
      : <p>No more tasks...</p> }
    </Box>
  ); 
});


function TriNetra() {
  const [ refresh, setRefresh ] = useState(0);  
  const [ currentTask, setCurrentTask ] = useState(null);
  // disable submit button when task is in submit state
  const [ isDisabled, setIsDisabled ] = useState(false);
  const idleCount = useRef(0);
  const hasTask = useRef(false);
  const navigate = useNavigate(); 
 
  useEffect(() => {
    // setup intervals every 30 seeconds
    const interval = setInterval(autoLogOut, 30000); // miliseconds
    return () => { clearInterval(interval); };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!currentTask) {
      // do not remove user if there is no task to submit
      idleCount.current = 0;
      // fetch tasks data from database
      const data = { 'Action': 'get_tasks', 'Email': localStorage.getItem('email') };
      post('/trinetra', data).then((result) => {
        if (result) { 
          setCurrentTask(result); 
          hasTask.current = true;
        };
      });
    };
    // eslint-disable-next-line
  },[refresh]);

  const autoLogOut = () => {
    idleCount.current = idleCount.current + 1;
    // refresh every 30 second if current task is null
    if (!hasTask.current) {
      setRefresh(refresh => refresh + 1);
    };
    // update backend for active users
    if (idleCount.current > 2) {
      const data = {
        'Action': 'update_activity',
        'Email': localStorage.getItem('email')};
      post('/trinetra', data);
    };
    // log out user if idle for 5 minutes or more
    if (idleCount.current > 9) {
      const data = {
        'Action': 'remove_tasks',
        'Message': `Auto logout! Reviewer [${localStorage.getItem('email')}] is idle for more than 5 minutes.`,
        'Email': localStorage.getItem('email')};
      post('/trinetra', data).then(() => {navigate('/');});
    };
  };

  const submitTask = (result) => {
    // display edited values
    currentTask['prediction'] = result['correction'];
    setIsDisabled(true);
    // submit task to backend
    const data = {
      'Action': 'submit_tasks',
      'Email': localStorage.getItem('email'),
      'Tasks': [result] };
    post('/trinetra', data).then((response) => {
      // reset task after server response
      setCurrentTask(null);
      hasTask.current = false;
      setIsDisabled(false);
      setRefresh(refresh => refresh + 1);
    });
  };

  return (
    <AccessManager>
      <Box sx={{ bgcolor: '#e1f5fe' }}>
        <Grid container>
          <Grid item xs={12}>
            <Header> TriNetra </Header>
          </Grid>
          <Grid item xs={12}>
            <Body 
              task={currentTask}
              isDisabled={isDisabled}
              onSubmit={submitTask} /> 
          </Grid>
        </Grid>
      </Box> 
    </AccessManager>
  );
}

export default TriNetra;
