import React, { useEffect, useState } from 'react';
import { Box, Dialog, Divider } from '@mui/material';
import { Grid, InputLabel, TextField, Typography } from '@mui/material';
import { Header, post, MyButton } from '../components';
import AccessManager from '../authentication/AccessManager';


function TicketForm(props) {
  const [resolution, setResolution] = useState('');
  const handleChange = (event) => { setResolution(event.target.value); };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.onSubmit(resolution);
  };

  return (
    <Dialog onClose={ props.onClose } open={ props.open }>
      <Box
        component='form'
        noValidate
        sx={{
          m: 5,
          display: 'grid',
          gridTemplateColumns: '1fr',
          gap: 2,
        }} 
        onSubmit={ handleSubmit } >
        <TextField
          name='Resolution'
          id='Resolution'
          label='Resolution'
          multiline
          rows={5}
          value={ resolution }
          onChange={ handleChange } />
        <Divider />
        <MyButton 
          sx={{ mt: 5 }} 
          type='submit'> 
            Submit 
        </MyButton>
      </Box>
    </Dialog>
  );
}


function Title() {
  return (
    <Box
      sx={{
        minWidth: '10%', 
        maxWidth: '100%',
        ml: 10,
        display: 'grid',
        gridTemplateColumns: '1fr 4fr 1fr',
        gap: 10 }}>
      <InputLabel>Issue</InputLabel>
      <InputLabel>Description</InputLabel>
      <InputLabel>Action</InputLabel>
    </Box>
  );
}


function Ticket(props) {
  return (
    <Box
      sx={{
        minWidth: '10%', 
        maxWidth: '100%',
        ml: 5,
        mt: 2,
        mb: 2,
        display: 'grid',
        gridTemplateColumns: '1fr 4fr 1fr',
        gap: 10 }}>
      <Box
        sx={{
          minWidth: '10%', 
          maxWidth: '100%',
          display: 'grid',
          gridTemplateColumns: '1fr' }}>
        <Typography  variant='h4' >{ props.ticket.issue_type }</Typography>
        <Typography  variant='h6' >{ props.ticket.task_id }</Typography>
        <Typography  variant='h6' >{ props.ticket.task_execution_time }</Typography>
      </Box>
      <Typography variant='h5'>{ props.ticket.description }</Typography> 
      <Box
        sx={{
          minWidth: '10%', 
          maxWidth: '100%',
          mr: 10,
          display: 'grid',
          gridTemplateColumns: '1fr',
          gap: 10 }}>
        <MyButton  
          onClick={ props.onClick }>
            Resolve
        </MyButton>
      </Box>
    </Box>
  );
}


function CSTNetraModule() {
  const [isOpen, setIsOpen] = useState(false);
  const [ticketList, setTicketList] = useState([]);
  const [ticket, setTicket] = useState({});
  const [totalTask, setTotalTask] = useState(0);
  const [updated, setUpdated] = useState(0);
  
  useEffect(() => {
    // fetch camera data from database
    const data = { 'Action': 'get_tickets', 'App': 'netra' };
    post('/cst', data).then((result) => { 
          const sorted_tasks = result.sort((a, b) => (a.priority > b.priority));
          setTicketList(sorted_tasks);
          setTotalTask(sorted_tasks.length) });
    // refresh every 60 second if resolve button is not open
    if (!isOpen) {
      const interval = setInterval(() => setUpdated(updated + 1), 60000);
      return () => {
        clearInterval(interval);
      };};
  },[isOpen, updated]);

  const handleSubmit = (resolution) => {
    const data = {
      'Action': 'resolve_ticket',
      'App': ticket.app,
      'Ticket': ticket,
      'Resolution': resolution,
      'Resolver': localStorage.getItem('email') };  // current loged-in user
    post('/cst', data);
    setIsOpen(false);
    setUpdated(updated + 1);
  };

  return (
    <AccessManager url='/cstmodule'>
      <Grid container>
        <Grid item xs={12}>
          <Header total_notification={totalTask}> CST Netra Module </Header>
        </Grid>
        <Grid item xs={12}>
          <Box  sx={{ mt: 5 }}>
            <Title />
            { ticketList.map((ticket, i) => {
                return (
                  <Box key={i}>            
                  <Divider />
                  <Ticket ticket={ticket} onClick={ ()=>{setIsOpen(true); setTicket(ticket);} } />
                  </Box>
                );
              })}  
            <TicketForm
              open={ isOpen }
              onSubmit={ handleSubmit }
              onClose={ ()=>{setIsOpen(false);} } />
          </Box>
        </Grid>
      </Grid>
    </AccessManager>
  );
}

export default CSTNetraModule;
