import React, { useEffect, useState } from 'react';
import { Box, Dialog, DialogTitle, DialogContent, DialogActions, Divider, InputLabel, TextField, IconButton, Button, Typography, MenuItem, Select } from '@mui/material';
import { MyButton, post } from '../components';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever'; // eslint-disable-next-line
import Stack from '@mui/material/Stack'; // For horizontal layout


function UnitForm(props) {
  const [unit_name, setUnitName] = useState('');

  useEffect(() => { setUnitName('') }, [props]);
  const changeName = (event) => { setUnitName(event.target.value); };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.onSubmit(unit_name);
  };

  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <Box
        component='form'
        noValidate
        sx={{
          m: 5,
          display: 'grid',
          gridTemplateColumns: 'repeat(1, 1fr)',
          gap: 2,
        }}
        onSubmit={handleSubmit} >
        <TextField
          name='Unit Name'
          id='Unit Name'
          label='Unit Name'
          value={unit_name}
          onChange={changeName} />
        <MyButton
          sx={{ mt: 5 }}
          type='submit'>
          Submit
        </MyButton>
      </Box>
    </Dialog>
  );
}


function AddCamera(props) {
  const [ip, setIP] = useState('');

  useEffect(() => { setIP('') }, [props]);
  const handleChange = (event) => { setIP(event.target.value); };

  const handleSubmit = (event) => {
    event.preventDefault();
    props.onSubmit(ip);
  };

  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <Box
        component='form'
        noValidate
        sx={{
          m: 5,
          display: 'grid',
          gridTemplateColumns: 'repeat(1, 1fr)',
          gap: 2,
        }}
        onSubmit={handleSubmit} >
        <TextField
          name='Camera IP'
          id='Camera IP'
          label='Camera IP'
          onChange={handleChange} />
        <MyButton
          sx={{ mt: 5 }}
          type='submit'>
          Submit
        </MyButton>
      </Box>
    </Dialog>
  );
}


function Title(props) {

  return (
    <Box
      sx={{
        minWidth: '10%',
        maxWidth: '100%',
        ml: 5,
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        gap: 10
      }}>

      <InputLabel>Unit</InputLabel>
      <InputLabel>Cameras</InputLabel>
      <InputLabel>Task</InputLabel>
      <InputLabel>Status</InputLabel>
      <InputLabel>Timeslot</InputLabel>
      <InputLabel>Frequency</InputLabel>
      <InputLabel>Action</InputLabel>
    </Box>
  );
}


function Unit(props) {
  const [isOpen, setIsOpen] = useState(false);
  const hospitalName = props.hospitalName;

  const unit_name = props.unit_name[0]
  const unit_list = props.unit_name[1]
  const camera_ip_list = props.unit_name[1].cameras || []

  const task_list = Object.keys(props.unit_name[1]).filter(key => key !== 'cameras');
  const timeslotList = task_list.map(key => unit_list[key]?.timeslot);
  const frequencyList = task_list.map(key => unit_list[key]?.frequency);
  const status_list = task_list.map(key => unit_list[key]?.status);

  const handleIPSubmit = (ip) => {
    if (!ip) return;
    if (camera_ip_list.includes(ip)) return;
    const data = {
      "Action": "set_camera",
      "AuthEmail": localStorage.getItem('email'),
      "HospitalName": hospitalName,
      'Cameras': ip,
      'UnitName': unit_name
    };
    post('/hospitals', data)
    setIsOpen(false);
    // setUpdated(updated + 1);
    handleUpdate()
  };

  const deleteCamera = (index) => {
    const camera_ip = camera_ip_list[index];
    const data = {
      "Action": "remove_camera",
      "AuthEmail": localStorage.getItem('email'),
      "HospitalName": hospitalName,
      'Cameras': camera_ip,
      'UnitName': unit_name
    };
    post('/hospitals', data);
    handleUpdate()
  };

  const [task, setTask] = useState('');
  var [status, setStatus] = useState('ACTIVE');
  const [timeslot, setTimeslot] = useState(0);
  const [frequency, setFrequency] = useState(60);


  const selectStatus = (event) => { setStatus(event.target.value); };
  const selectTimeslot = (event) => { setTimeslot(event.target.value); };
  const selectFrequency = (event) => { setFrequency(event.target.value); };
  const [confirmationOpen, setConfirmationOpen] = useState(false);


  const deleteUnit = () => {
    const data = {
      "Action": "remove_unit",
      "AuthEmail": localStorage.getItem('email'),
      "HospitalName": hospitalName,
      'UnitName': unit_name
    };
    post('/hospitals', data);
    handleUpdate();
  };

  const [updated, setUpdated] = useState(0);
  const [open, setOpen] = useState(false);

  const handleUpdate = () => {
    setUpdated(updated + 1);
    props.onUpdate();
  };

  const handleClose = () => {
    setOpen(false);
  };

  const inferStatus = (status) => {
    if (status === "ACTIVE") {
      return true;
    } else if (status === "INACTIVE") {
      return false;
    }
    else if (status === true) {
      return "ACTIVE"
    }
    else if (status === false) {
      return "INACTIVE"
    }
  }

  const [selectedTask, setSelectedTask] = useState('ACTIVE');
  const handleTaskSubmit = () => {
    status = inferStatus(status)
    if (!selectedTask) return;

    const data = {
      "Action": "edit_task",
      "Timeslot": timeslot,
      "Frequency": frequency,
      "status": status,
      'task': task,
      'HospitalName': hospitalName,
      'UnitName': unit_name
    }
    post('/hospitals', data)
    handleUpdate()
    setOpen(false);
  };

  const handleButtonClick = (task, index, props) => {

    setSelectedTask(task);
    setOpen(true);
    setTimeslot(props.unit_name[1][task_list[index]].timeslot);
    setFrequency(props.unit_name[1][task_list[index]].frequency);
    setStatus(inferStatus(props.unit_name[1][task_list[index]].status));
    setTask(task);
  };


  return (
    <Box
      sx={{
        minWidth: '10%',
        maxWidth: '100%',
        ml: 5,
        mt: 0.4,
        mb: 1,
        display: 'grid',
        gridTemplateColumns: 'repeat(7, 1fr)',
        gap: 2,
      }}>

      {/* BLOCK FOR UNIT NAME  */}
      <Box>
        <Stack direction="row" spacing={2} alignItems="center" sx={{ gridTemplateColumns: 'repeat(2, 0fr)' }}>
          <InputLabel sx={{
            fontSize: '1.2rem',
            color: 'black',
            textTransform: 'uppercase'
          }}>{unit_name}</InputLabel>
          <div direction="row" sx={{ gridTemplateColumns: 'repeat(2, 0fr)' }}>
            {/* Delete Button */}
            <Button variant="contained" color="secondary" size="small"
              startIcon={<DeleteForeverIcon fontSize="large" color='secondary' />}
              onClick={() => setConfirmationOpen(true)} >
              DEL
            </Button>
            <Dialog open={confirmationOpen} onClose={() => setConfirmationOpen(false)}>
              <DialogContent sx={{ fontSize: '25px' }}>
                Are you sure you want to delete "{unit_name}"?
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setConfirmationOpen(false)} sx={{ backgroundColor: "#C0C0C0", fontSize: "large" }}  >Cancel</Button>
                <Button onClick={deleteUnit} color="error" sx={{ backgroundColor: '#DEB887', fontSize: "large" }}>Delete</Button>
              </DialogActions>
            </Dialog>
            <Button variant="contained" color="primary" size="small"
              onClick={() => { setIsOpen(true); }} >
              ADD CAM
            </Button>
            <AddCamera
              open={isOpen}
              onSubmit={handleIPSubmit}
              onClose={() => { setIsOpen(false); }} />
          </div>
        </Stack>
      </Box>

      {/* BLOCK FOR CAMERAS */}
      <Box
        sx={{
          minWidth: '5%',
          maxWidth: '10%',
          mt: 2, // Add margin top for separation from the previous block
          display: 'grid',
          gridTemplateColumns: 'repeat(1, 1fr)',
          gap: 2, // Add some spacing between items
          mb: -1.5
        }}>
        {camera_ip_list.map((camera, index) => (
          <Box
            key={index}
            sx={{
              display: 'flex',
              alignItems: 'center',
              mb: 1
            }}>
            <InputLabel sx={{ ml: 1, fontSize: '1.5rem' }}>
              {camera}
            </InputLabel>
            <IconButton
              onClick={() => deleteCamera(index)}
              aria-label="delete"
              color="secondary"
              sx={{ ml: 1 }}
            >
              <DeleteForeverIcon fontSize="large" />
            </IconButton>
          </Box>
        ))}
      </Box>

      {/* BLOCK FOR TASK */}
      <Box>
        <Box
          sx={{
            minWidth: '5%',
            maxWidth: '10%',
            mt: 1, // Add margin top for separation from the previous block
            display: 'grid',
            gridTemplateColumns: 'repeat(1, 1fr)',
            gap: 2, // Add some spacing between items
          }}>
          {task_list.map((task, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 1
              }}>
              <InputLabel sx={{ ml: '5px', fontSize: '1.5rem' }}>
                {task}
              </InputLabel>
            </Box>
          ))}
        </Box>
      </Box>

      {/* BLOCK FOR STATUS */}
      <Box>
        <Box sx={{
          minWidth: '5%',
          maxWidth: '10%',
          mt: 1, // Add margin top for separation from the previous block
          display: 'grid',
          gridTemplateColumns: 'repeat(1, 1fr)',
          gap: 2, // Add some spacing between items
        }}>
          {status_list.map((status, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'center',
                mb: 1
              }}>
              <InputLabel sx={{ ml: '10px', fontSize: '1.5rem' }}>
                {status ? 'Active' : 'Inactive'}
              </InputLabel>
            </Box>
          ))}
        </Box>
      </Box>

      {/* BLOCK FOR TIMESLOT */}
      <Box>
        <Box sx={{
          minWidth: '5%',
          maxWidth: '10%',
          mt: 1, // Add margin top for separation from the previous block
          display: 'grid',
          gridTemplateColumns: 'repeat(1, 1fr)',
          gap: 2, // Add some spacing between items
        }}>
          {timeslotList.map((ts, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'right',
                mb: 1
              }}>
              <InputLabel sx={{ ml: '10px', fontSize: '1.5rem' }}>
                {ts}
              </InputLabel>
            </Box>
          ))}
        </Box>
      </Box>

      {/* BLOCK FOR FREQUENCY */}
      <Box>
        <Box sx={{
          minWidth: '5%',
          maxWidth: '10%',
          mt: 1, // Add margin top for separation from the previous block
          display: 'grid',
          gridTemplateColumns: 'repeat(1, 1fr)',
          gap: 2, // Add some spacing between items
        }}>
          {frequencyList.map((frequency, index) => (
            <Box
              key={index}
              sx={{
                display: 'flex',
                alignItems: 'right',
                mb: 1
              }}>
              <InputLabel sx={{ ml: '10px', fontSize: '1.5rem' }}>
                {frequency}
              </InputLabel>
            </Box>
          ))}
        </Box>
      </Box>

      {/* BLOCK FOR ACTION AND EDIT BUTTON */}
      <Box>
        <Box sx={{
          minWidth: '5%',
          maxWidth: '10%',
          mt: 1,
          display: 'grid',
          gridTemplateColumns: 'repeat(1, 1fr)',
          gap: 2,
        }}>
          {task_list.map((task, index) => (
            <Box key={index} sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              mb: 1,
            }}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleButtonClick(task, index, props)}
              >
                EDIT
              </Button>
            </Box>
          ))}
        </Box>
        <Dialog onClose={handleClose} open={open}>
          <DialogTitle>EDIT TASK</DialogTitle>
          <DialogContent>
            <Typography>Status</Typography>
            <Select value={status} onChange={selectStatus} sx={{ mb: 2 }}>
              <MenuItem value={'ACTIVE'}>ACTIVE</MenuItem>
              <MenuItem value={'INACTIVE'}>INACTIVE</MenuItem>
            </Select>
            <TextField
              name='Timeslot'
              id='Timeslot'
              label='Timeslot'
              type='number'
              value={timeslot}
              onChange={selectTimeslot}
              sx={{ mb: 2 }}
              fullWidth
            />
            <TextField
              name='Frequency'
              id='Frequency'
              label='Frequency'
              type='number'
              value={frequency}
              onChange={selectFrequency}
              fullWidth
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="secondary">Cancel</Button>
            <Button onClick={handleTaskSubmit} color="primary">Save</Button>
          </DialogActions>
        </Dialog>
      </Box>
    </Box>
  );
}


function UnitModule(props) {
  const hospitalName = props.hospital_name
  const [unitList, setUnitList] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [updated, setUpdated] = useState(0);

  useEffect(() => {
    // fetch hospital data from database
    const data = {
      'Action': 'get_units',
      'AuthEmail': localStorage.getItem('email'),
      'HospitalName': props.hospital_name
    };
    post('/hospitals', data).then((result) => {
      setUnitList(result);
    });
  }, [updated, props.hospital_name]);

  const handleUpdate = () => {
    setUpdated(updated + 1);
    props.onUpdate();
  };

  const addCameras = (unit_name, cameras) => {
    const data = {
      'Action': 'set_unit',
      'AuthEmail': localStorage.getItem('email'),
      'HospitalName': props.hospital_name,
      'UnitName': unit_name,
      'Cameras': cameras
    };
    post('/hospitals', data);
    handleUpdate();
  }

  const addUnit = (unit_name) => {
    if (!unit_name) return;
    const data = {
      'Action': 'set_unit',
      'AuthEmail': localStorage.getItem('email'),
      'HospitalName': props.hospital_name,
      'UnitName': unit_name,
      'Cameras': []
    };
    post('/hospitals', data);
    setIsOpen(false);
    handleUpdate();
  };

  const handleDelete = (unit_name) => {
    const data = {
      'Action': 'remove_unit',
      'AuthEmail': localStorage.getItem('email'),
      'HospitalName': props.hospital_name,
      'UnitName': unit_name
    };
    post('/hospitals', data);
    handleUpdate();
  };

  return (
    <Box sx={{
      mb: 2,
      bgcolor: '#ffffff',
      border: 5
    }}>
      <Title />
      {Object.entries(unitList).map((unit_name, unit_data) => {
        return (
          <Box key={unit_name[0]}>
            <Divider />
            <Unit unit_name={unit_name} hospitalName={hospitalName} cameras={unit_data.cameras || []} onUpdate={handleUpdate} onDelete={handleDelete} onSubmit={addCameras} />
          </Box>
        );
      })}
      <MyButton
        sx={{ mt: 10, ml: 2 }}
        onClick={() => { setIsOpen(true); }}>
        + Add Unit
      </MyButton>
      <MyButton
        sx={{ mt: 10, ml: 2 }}
        onClick={props.onClose}>
        close
      </MyButton>
      <UnitForm
        open={isOpen}
        onSubmit={addUnit}
        onClose={() => { setIsOpen(false); }} />
    </Box>
  );
};


export default UnitModule;
