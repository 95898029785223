import { Box, Grid } from '@mui/material';
import { useNavigate } from "react-router-dom";
import AccessManager from '../authentication/AccessManager';
import { Container } from '@mui/material';
import { Header, MyButton, APP_ADDRESS } from '../components';

function Body() {
  const navigate = useNavigate();
  return (
    <Container component='main' maxWidth='xs' sx={{ minHeight: 700 }}>
      <Box
        sx={{
        mt: 5,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'}}> 
        <MyButton 
          sx={{ mt: 5,  minWidth: '100%' }} 
          onClick={()=>{ navigate('netra') }}> 
          { APP_ADDRESS['/cstmodule/netra'] } 
        </MyButton> 
      </Box>
    </Container>
  );
}


function CSTModule() {
  return (
    <AccessManager>
      <Grid container>
        <Grid item xs={12}>
          <Header> CST Module </Header>
        </Grid>
        <Grid item xs={12}>
          <Body /> 
        </Grid>
      </Grid>
    </AccessManager>
  );
}

export default CSTModule;
