import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './authentication/Login';
import NoPage from './components/NoPage'
import TriNetra from './trinetra/TriNetra';
import { Roles, SuperAdmin, Admin } from './roles';
import CameraModule from './camera_module/Camera';
import HospitalModule from './hospital_module/Hospital';
import { CSTModule, CSTNetraModule } from './cst_module';


function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/'>
          <Route index element={<Login />} />
          <Route path='roles' element={<Roles />} />
          <Route path='superadmin' element={<SuperAdmin />} />
          <Route path='admin' element={<Admin />} />
          <Route path='trinetra' element={<TriNetra />} />
          <Route path='cameramodule' element={<CameraModule />} />
          <Route path='hospitalmodule' element={<HospitalModule />} />
          <Route path='cstmodule' element={<CSTModule />} />
          <Route path='cstmodule/netra' element={<CSTNetraModule />} />
          <Route path='*' element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
