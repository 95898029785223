import React, { useEffect, useState } from 'react';
import { Box, Container } from '@mui/material';
import Grid from '@mui/material/Grid';
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from '@mui/material/styles';
import { theme, Header, MyButton, APP_ADDRESS, post } from '../components';


function Body(props) {
  const navigate = useNavigate();
  return (
    <ThemeProvider theme={theme}>
      <Container component='main' maxWidth='xs' sx={{ minHeight: 700 }}>
        <Box
          sx={{
          mt: 5,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'}}> 
          {props.roles.length > 0 ?
            props.roles.map((role, i) => {
              return(
                <MyButton 
                  sx={{ mt: 5,  minWidth: '100%' }} 
                  key={i} 
                  onClick={()=>{ navigate(role.url) }}> 
                  { APP_ADDRESS[role.url] } 
                </MyButton> 
              );
            }) :
            <h1>You don't have any Roles!</h1>
          }
        </Box>
      </Container>
    </ThemeProvider>
  );
}

function Roles() {
  const navigate = useNavigate();
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    if (localStorage.getItem('email') === null) {
      navigate('/');
    };
    
    const data = {
      'Action': 'get_roles',
      'Url': window.location.pathname,
      'Email': localStorage.getItem('email')
    };
    post('/roles', data).then((response)=>{setRoles(response)});
  },[navigate]);
  
  return (
    <ThemeProvider theme={theme}>
      <Grid container>
        <Grid item xs={12}>
             <Header> Roles </Header>
           </Grid>
           <Grid item xs={12}>
             <Body roles={roles}/> 
           </Grid>
      </Grid>
    </ThemeProvider>
  );
}

export default Roles;
