import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: { main: '#4615b2' },
    secondary: { main: '#f50057' },
    background: {
      default: '#e1f5fe'
    }
  },
  components: {
    MuiGrid: {
      defaultProps: {
        sx: {
          bgcolor: '#e1f5fe'
        }
      }
    },
    MuiAvatar: {
      defaultProps: {
        sx: {
          bgcolor: '#4615b2'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          boxSizing: 'content-box',
          fontSize: '1.12rem',
          fontWeight: 'bold'
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        sx: {
          fontSize: 26,
          fontWeight: 'bold',
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: 35,
          fontWeight: 'bold',
        },
      },
    },
  },

  overrides: {
    MuiButton: {
      raisedPrimary: {
        color: 'white',
      },
    },
  }
});

export default theme;