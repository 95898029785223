import React, { useState, useEffect } from 'react';
import { Box, Button, InputLabel, InputBase, FormControl, FormLabel } from '@mui/material';
import { alpha, styled, createTheme, ThemeProvider } from '@mui/material/styles';


const theme = createTheme({
  components: {
    MuiInputLabel: {
        defaultProps: {
            sx: {
                fontSize: 26,
                fontWeight: 'bold',
            },
        },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
              fontSize: 35,
              fontWeight: 'bold',
          },
      },
    },
  },
  palette: {
      primary: { main: '#4615b2'},
      secondary: { main: '#f50057'},
  },
});
  

const BootstrapInput = styled(InputBase)(({ theme }) => ({
  'label + &': {
    marginTop: theme.spacing(3),
  },
  '& .MuiInputBase-input': {
    borderRadius: 4,
    position: 'relative',
    border: '1px solid #000',
    fontSize: 16,
    width: 'auto',
    padding: '1px 12px',
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&:focus': {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
}));


function ReviewForm(props) {
  const [inputs, setInputs] = useState({});
  
  useEffect(()=>{
    setInputs(props.inputs);
  },[props])

  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setInputs(values => ({...values, [name]: value}));
  }

  const handleSubmit = (event) => {
    event.preventDefault();
    const result = {'correction': inputs, 'task_id': props.task_id}
    props.updateTask(result);
  }

  return (
    <Box
      component='form'
      noValidate
      sx={{
        display: 'grid',
        gridTemplateColumns: 'repeat(1, 1fr)',
        gap: 2,
      }} 
      onSubmit={handleSubmit} >
      <ThemeProvider theme={theme}>
        <FormLabel>
          Review Vitals
        </FormLabel>
        <FormControl>
          <InputLabel shrink htmlFor='HR'>
            HR
          </InputLabel>
          <BootstrapInput name='HR' value={inputs.HR || ''} onChange={handleChange}/>
        </FormControl>
        <FormControl>
          <InputLabel shrink htmlFor='SPO2'>
            SPO2
          </InputLabel>
          <BootstrapInput name='SPO2' value={inputs.SPO2 || ''} onChange={handleChange}/>
        </FormControl>
        <FormControl>
          <InputLabel shrink htmlFor='RR'>
            RR
          </InputLabel>
          <BootstrapInput name='RR' value={inputs.RR || ''} onChange={handleChange}/>
        </FormControl>
        <FormControl>
          <InputLabel shrink htmlFor='SBP'>
            SBP
          </InputLabel>
          <BootstrapInput name='SBP' value={inputs.SBP || ''} onChange={handleChange}/>
        </FormControl>
        <FormControl>
          <InputLabel shrink htmlFor='DBP'>
            DBP
          </InputLabel>
          <BootstrapInput name='DBP' value={inputs.DBP || ''} onChange={handleChange}/>
        </FormControl>
        <FormControl>
          <InputLabel shrink htmlFor='MAP'>
            MAP
          </InputLabel>
          <BootstrapInput name='MAP' value={inputs.MAP || ''} onChange={handleChange}/>
        </FormControl>
      </ThemeProvider>
      <Box 
        sx={{
          display: 'grid',
          mt: 5,
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: 7
        }}>
        <Button variant='contained' size='medium' type='submit' disabled={props.isDisabled}>
          Submit
        </Button>
        {/* <Button variant='contained' size='medium' disabled={props.isDisabled}>
          Delete
        </Button> */}
      </Box>
    </Box>
  );
}

export default React.memo(ReviewForm);