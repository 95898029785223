import React, { useEffect, useState } from 'react';
import { Box, Checkbox, Dialog, Divider, FormControlLabel } from '@mui/material';
import { Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import AccessManager from '../authentication/AccessManager';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
// import EditIcon from '@mui/icons-material/Edit';
import { Header, MyButton, APP_ADDRESS, post } from '../components';


function AdminForm(props) {
  const [email, setEmail] = useState('');
  const [checked, setChecked] = useState([]);

  const changeEmail = (event) => { setEmail(event.target.value); };

  const handleChecked = (event, index) => {
    const checkedItem = [];
    const length = props.privilege.length;
    for (let i=0; i<length; i++) {
      if(i === index) {
        checkedItem.push(event.target.checked);
      } else {
        checkedItem.push(checked[i])
      };
    };
    setChecked(checkedItem)
  };

  useEffect(() => {
    setEmail(props.user.email || '');
    const checkedItem = []; 
    for (let url of props.privilege) {
      let privilegeValue = false;
      if(typeof props.user.privilege !== 'undefined') {
        if(typeof props.user.privilege.find((str) => str === url) !== 'undefined') {
          privilegeValue = true;
        };
      };
      checkedItem.push(privilegeValue);
    };
    setChecked(checkedItem);
  },[props]);

  const handleSubmit = (event) => {
    event.preventDefault();
    const privilege = [];
    const length = props.privilege.length;
    for (let i=0; i<length; i++) {
      if(checked[i]) {
        privilege.push(props.privilege[i]);
      };
    };
    props.onSubmit(email, privilege);
  };

  return (
    <Dialog onClose={ props.onClose } open={ props.open }>
      <Box
        component='form'
        noValidate
        sx={{
          m: 5,
          display: 'grid',
          gridTemplateColumns: 'repeat(1, 1fr)',
          gap: 2,
        }} 
        onSubmit={ handleSubmit } >
        <TextField
          name='email'
          id='email'
          label='email'
          value={ email }
          onChange={ changeEmail } />
        <Divider />    
        <Select value={'USER'}>
          <MenuItem value={'USER'}>USER</MenuItem>
        </Select>
        <Divider />    
        { props.privilege.map((url, i) => {
          return (
            <FormControlLabel
              key={i}
              label={APP_ADDRESS[url]}
              control={ <Checkbox checked={checked[i]} onChange={(event)=>{handleChecked(event,i)}}/> } />
          );
        })}
        <MyButton 
          sx={{ mt: 5 }} 
          type='submit'>
            Submit
        </MyButton>
      </Box>
    </Dialog>
  );
}


function Title() {
  return (
    <Box
      sx={{
        minWidth: '10%', 
        maxWidth: '100%',
        ml: 10,
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: 10 }}>
      <InputLabel>Email</InputLabel>
      <InputLabel>Apps</InputLabel>
      <InputLabel>Role</InputLabel>
      <InputLabel>Action</InputLabel>
    </Box>
  );
}


function User(props) {
  return (
    <Box
      sx={{
        minWidth: '10%', 
        maxWidth: '100%',
        ml: 10,
        mt: 2,
        mb: 2,
        display: 'grid',
        gridTemplateColumns: 'repeat(4, 1fr)',
        gap: 10 }}>
      <InputLabel>{ props.user.email }</InputLabel>
      <InputLabel>{ APP_ADDRESS[props.user.privilege] }</InputLabel> 
      <InputLabel>{ 'USER' }</InputLabel>
      <Box
        sx={{
          minWidth: '10%', 
          maxWidth: '100%',
          mr: 10,
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: 10 }}>
        <MyButton  
          startIcon={<DeleteForeverIcon fontSize="large" color='secondary' />} 
          onClick={() =>  { props.onDelete(props.user); }}>
            Delete
        </MyButton>
        {/* <MyButton  
          startIcon={<EditIcon fontSize="large" color='secondary' />} 
          onClick={() => { props.onUpdate(props.user); }}>
            Edit
        </MyButton> */}
      </Box>
    </Box>
  );
}


const Body = function Body() {
  const [isOpen, setIsOpen] = useState(false);
  const [users, setUsers] = useState([]);
  const [newUser, setNewUser] = useState({});
  const [updated, setUpdated] = useState(0);
  const [privilege, setPrivilege] = useState([]);

  const handleClickOpen = (user) => { setNewUser(user); setIsOpen(true); };
  const handleClose = () => { setNewUser({}); setIsOpen(false); };


  useEffect(() => {
    const data = {
      'Action': 'get_users',
      'AuthEmail': localStorage.getItem('email'),
      'Url': window.location.pathname,
      'Email': localStorage.getItem('email') };
    post('/roles', data).then((result) => { 
      setPrivilege(result['Privilege']);
      const user_list = result['Users'];
      const sorted_users = user_list.sort((a, b) => a.email.localeCompare(b.email));
      setUsers(sorted_users); });
  },[updated]);

  const handleSubmit = (email, privilege) => {
    // user can have only one privilege for app
    for (let url of privilege) {
      const data = {
        'Action': 'set_role',
        'AuthEmail': localStorage.getItem('email'),
        'Url': url,
        'Privilege': [url],
        'Email': email };
        post('/roles', data);
    };
    handleClose();
    setUpdated( updated + 1);
  };

  const handleDelete = (user) => { 
    const data = {
      'Action': 'remove_role',
      'AuthEmail': localStorage.getItem('email'),
      'Url': user.url,
      'Privilege': user.privilege,
      'Email': user.email };
    post('/roles', data);
    // rerender after delete
    setUpdated( updated + 1); 
  };

  return (
    <Box  sx={{ mt: 5 }}>
      <Title />
      { users.map((user, i) => {
          return (
            <Box key={i}>            
            <Divider />
            <User user={user} onDelete={handleDelete} onUpdate={handleClickOpen} />
            </Box>
          );
        })}  
      <MyButton 
        sx={{ mt: 5, minWidth: '10%' }} 
        onClick={ handleClickOpen }>
          + Add User
      </MyButton>
      <AdminForm
        user={ newUser }
        privilege={ privilege }
        open={ isOpen }
        onSubmit={ handleSubmit }
        onClose={ handleClose } />
    </Box>
  );
};


function Admin() {
  return (
    <AccessManager>
      <Grid container>
        <Grid item xs={12}>
          <Header> Admin </Header>
        </Grid>
        <Grid item xs={12}>
          <Body /> 
        </Grid>
      </Grid>
    </AccessManager>
  );
};

export default Admin;
