const APP_ADDRESS = {
    '/superadmin': 'Super Admin',
    '/admin': 'Admin',
    '/trinetra': 'Trinetra',
    '/cameramodule': 'Camera Module',
    '/hospitalmodule': 'Hospital Module',
    '/cstmodule': 'CST Module',
    '/cstmodule/netra': 'CST Netra Module'
};

export const APP_URLS = [
    '/cameramodule',
    '/hospitalmodule',
    '/trinetra',
    '/cstmodule',
];

export default APP_ADDRESS;