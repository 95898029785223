import React, { useEffect, useState } from 'react';
import { Box, Dialog, Divider } from '@mui/material';
import { Grid, InputLabel, TextField, MenuItem, Select, DialogContent, DialogActions, Button } from '@mui/material';
import { Header, post, MyButton } from '../components';
import AccessManager from '../authentication/AccessManager';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';


function CameraForm(props) {
  const [ip, setIp] = useState(props.camera.ip);
  const [userName, setUserName] = useState(props.camera.username);
  const [password, setPassword] = useState(props.camera.password);
  const [api, setAPI] = useState(props.camera.api);

  useEffect(() => {
    setIp(props.camera.ip || '');
    setUserName(props.camera.username || '');
    setPassword(props.camera.password || '');
    setAPI(props.camera.api || 'ONVIF');
  }, [props])


  const changeIp = (event) => { setIp(event.target.value); };
  const changeUserName = (event) => { setUserName(event.target.value); };
  const changePassword = (event) => { setPassword(event.target.value); };
  const selectAPI = (event) => { setAPI(event.target.value); };

  const handleSubmit = (event) => {
    event.preventDefault();
    const camera = {
      ip: ip,
      username: userName,
      password: password,
      api: api
    };
    props.onSubmit(camera);
  };

  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <Box
        component='form'
        noValidate
        sx={{
          m: 5,
          display: 'grid',
          gridTemplateColumns: 'repeat(1, 1fr)',
          gap: 2,
        }}
        onSubmit={handleSubmit} >
        <TextField
          name='Ip Address'
          id='Ip Address'
          label='Ip Address'
          value={ip}
          disabled={typeof props.camera.ip !== 'undefined'}
          onChange={changeIp} />
        <Divider />
        <TextField
          name='User Name'
          id='User Name'
          label='User Name'
          value={userName}
          onChange={changeUserName} />
        <Divider />
        <TextField
          name='Password'
          id='Password'
          label='Password'
          value={password}
          onChange={changePassword} />
        <Select value={api} onChange={selectAPI}>
          <MenuItem value={'ONVIF'}>ONVIF</MenuItem>
          <MenuItem value={'HIKVISION'}>HIKVISION</MenuItem>
        </Select>
        <MyButton
          sx={{ mt: 5 }}
          type='submit'>
          Submit
        </MyButton>
      </Box>
    </Dialog>
  );
}


function Title() {
  return (
    <Box
      sx={{
        minWidth: '10%',
        maxWidth: '100%',
        ml: 10,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 0.8fr 1.5fr',
        gap: 10
      }}>
      <InputLabel>Ip Address</InputLabel>
      <InputLabel>User Name</InputLabel>
      <InputLabel>Password</InputLabel>
      <InputLabel>API</InputLabel>
      <InputLabel>Action</InputLabel>
    </Box>
  );
}


function Camera(props) {
  const [camera, setCamera] = useState(props.camera);

  useEffect(() => {
    setCamera(props.camera);
  }, [props]);

  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleDelete = () => {
    // Call onDelete only if confirmed
    console.log('camera name', props.camera)
    props.onDelete(props.camera);
    // Close the confirmation dialog
    setConfirmationOpen(false);
  };


  return (
    <Box
      sx={{
        minWidth: '10%',
        maxWidth: '100%',
        ml: 10,
        mt: 2,
        mb: 2,
        display: 'grid',
        gridTemplateColumns: '1fr 1fr 1fr 0.8fr 1.5fr',
        gap: 10
      }}>
      <InputLabel>{camera.ip}</InputLabel>
      <InputLabel>{camera.username}</InputLabel>
      <InputLabel>{camera.password}</InputLabel>
      <InputLabel>{camera.api}</InputLabel>
      <Box
        sx={{
          minWidth: '10%',
          maxWidth: '100%',
          mr: 10,
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: 10
        }}>
        <MyButton
          startIcon={<DeleteForeverIcon fontSize="large" color='secondary' />}
          onClick={() => setConfirmationOpen(true)}>
          Delete
        </MyButton>
        <Dialog open={confirmationOpen} onClose={() => setConfirmationOpen(false)}>
          <DialogContent sx={{ fontSize: '25px' }}>
            Are you sure you want to delete IP {props.camera.ip}?
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setConfirmationOpen(false)} sx={{ backgroundColor: "#C0C0C0", fontSize: "large" }}  >Cancel</Button>
            <Button onClick={handleDelete} color="error" sx={{ backgroundColor: '#DEB887', fontSize: "large" }}>Delete</Button>
          </DialogActions>
        </Dialog>
        <MyButton
          startIcon={<EditIcon fontSize="large" color='secondary' />}
          onClick={() => { props.onEdit(camera); }}>
          Edit
        </MyButton>
      </Box>
    </Box>
  );
}


function Body() {
  const [isOpen, setIsOpen] = useState(false);
  const [cameraList, setCameraList] = useState([]);
  const [camera, setCamera] = useState({});
  const [updated, setUpdated] = useState(0);

  const handleEdit = (camera) => { setCamera(camera); setIsOpen(true); };
  const handleClose = () => { setCamera({}); setIsOpen(false); };

  useEffect(() => {
    // fetch camera data from database
    const data = { 'Action': 'get_cameras', 'AuthEmail': localStorage.getItem('email') };
    post('/cameras', data).then((result) => {
      const sorted_camera = result.sort((a, b) => a.ip.localeCompare(b.ip));
      setCameraList(sorted_camera);
    });
  }, [updated]);

  const handleSubmit = (camera) => {
    const data = {
      'Action': 'set_camera',
      'AuthEmail': localStorage.getItem('email'),
      'Ip': camera.ip,
      'UserName': camera.username,
      'Password': camera.password,
      'API': camera.api
    };
    post('/cameras', data);
    handleClose();
    setUpdated(updated + 1);
  };

  const handleDelete = (camera) => {
    const data = {
      'Action': 'remove_camera',
      'AuthEmail': localStorage.getItem('email'),
      'Ip': camera.ip,
      'UserName': camera.username,
      'Password': camera.password,
      'API': camera.api
    };
    post('/cameras', data);
    setUpdated(updated + 1);
  };

  return (
    <div>
      <MyButton
        sx={{ mt: 2, ml: 5 }}
        onClick={() => { setIsOpen(true); }}>
        + Add Camera
      </MyButton>
      <CameraForm
        camera={camera}
        open={isOpen}
        onSubmit={handleSubmit}
        onClose={handleClose} />
      <Box sx={{ mt: 5 }}>
        <Title />
        {cameraList.map((camera, i) => {
          return (
            <Box key={i}>
              <Divider />
              <Camera camera={camera} onDelete={handleDelete} onEdit={handleEdit} />
            </Box>
          );
        })}
      </Box>
    </div>
  );
};


function CameraModule() {
  return (
    <AccessManager>
      <Grid container>
        <Grid item xs={12}>
          <Header> Camera Module </Header>
        </Grid>
        <Grid item xs={12}>
          <Body />
        </Grid>
      </Grid>
    </AccessManager>
  );
}

export default CameraModule;
