import React, { useEffect, useState } from 'react';
import { Box, Dialog, Divider, MenuItem, Select, Typography } from '@mui/material';
import { Grid, InputLabel, TextField } from '@mui/material';
import { DialogContent, DialogActions, Button } from '@mui/material'
import { Header, MyButton, post } from '../components';
import AccessManager from '../authentication/AccessManager';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditIcon from '@mui/icons-material/Edit';
import UnitModule from './Units';


function HospitalForm(props) {
  const [hospitalName, setHospitalName] = useState('');
  const [review, setReview] = useState('AI_LOGIC');
  const [units, setUnits] = useState({});

  useEffect(() => {
    setHospitalName(props.hospital.hospital_name || '');
    setReview(props.hospital.review || 'AI_LOGIC');
    setUnits(props.hospital.units || {});
  }, [props]);

  const changeName = (event) => { setHospitalName(event.target.value); };
  const selectReview = (event) => { setReview(event.target.value); };

  const handleSubmit = (event) => {
    event.preventDefault();
    const hospital = {
      hospital_name: hospitalName,
      review: review,
      units: units
    };
    props.onSubmit(hospital);
  };

  return (
    <Dialog onClose={props.onClose} open={props.open}>
      <Box
        component='form'
        noValidate
        sx={{
          m: 3,
          display: 'grid',
          gridTemplateColumns: 'repeat(1, 1fr)',
          gap: 2,
          justifySelf: 'center'
        }}
        onSubmit={handleSubmit} >
        <TextField
          name='Hospital Name'
          id='Hospital Name'
          label='Hospital Name'
          value={hospitalName}
          disabled={typeof props.hospital.hospital_name !== 'undefined'}
          onChange={changeName} />
        <Divider />
        <Typography >
          Review
        </Typography>
        <Select value={review} onChange={selectReview}>
          <MenuItem value={'AI_LOGIC'}>AI_LOGIC</MenuItem>
          <MenuItem value={'ALWAYS'}>ALWAYS</MenuItem>
          <MenuItem value={'NEVER'}>NEVER</MenuItem>
        </Select>
        <Divider />
        <MyButton
          sx={{ mt: 3 }}
          type='submit'>
          Submit
        </MyButton>
      </Box>
    </Dialog>
  );
};


function Title() {
  return (
    <Box
      sx={{
        minWidth: '10%',
        maxWidth: '100%',
        ml: 10,
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: 10,
        justifyItems: 'center',
        alignItems: 'center',
        justifySelf: 'center'
      }}>
      <InputLabel>Hospital Name</InputLabel>
      <InputLabel sx={{ justifySelf: 'center' }} >Review Status</InputLabel>
      <InputLabel>Action</InputLabel>
    </Box>
  );
}


function Hospital(props) {
  const [hospital, setHospital] = useState(props.hospital);
  const [isUnitOpen, setIsUnitOpen] = useState(false);

  useEffect(() => { setHospital(props.hospital); }, [props]);

  const [confirmationOpen, setConfirmationOpen] = useState(false);

  const handleDelete = () => {
    // Call onDelete only if confirmed
    props.onDelete(props.hospital);
    // Close the confirmation dialog
    setConfirmationOpen(false);
  };


  return (
    <Box sx={{ display: 'grid', gridTemplateColumns: 'repeat(1, 1fr)' }}>
      <Box
        sx={{
          minWidth: '10%',
          maxWidth: '100%',
          ml: 10,
          mt: 2,
          mb: 2,
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          gap: 10,
        }}
      >
        <MyButton
          variant='outlined'
          onClick={() => { setIsUnitOpen(true); }}
        >
          {hospital.hospital_name}
        </MyButton>
        <InputLabel sx={{ textAlign: 'center' }}>{hospital.review}</InputLabel>
        <Box
          sx={{
            minWidth: '10%',
            maxWidth: '50%',
            mr: 10,
            display: 'grid',
            gridTemplateColumns: 'repeat(2, 1fr)',
            paddingLeft: '100px',
            justifyContent: 'center',
            alignItems: 'center',
            gap: 2,
          }}
        >
          <MyButton
            startIcon={<DeleteForeverIcon fontSize="large" color='secondary' />}
            onClick={() => { setConfirmationOpen(true); }}
          >
            Delete
          </MyButton>

          <Dialog
            open={confirmationOpen} onClose={() => setConfirmationOpen(false)} >
            <DialogContent sx={{ fontSize: '25px' }}>
              Are you sure you want to delete {props.hospital.hospital_name}?
            </DialogContent>
            <DialogActions>
              <Button onClick={() => setConfirmationOpen(false)} sx={{ backgroundColor: "#C0C0C0", fontSize: "large" }} >
                Cancel
              </Button>
              <Button onClick={handleDelete} color="error" sx={{ backgroundColor: '#DEB887', fontSize: "large" }} >
                Delete
              </Button>
            </DialogActions>
          </Dialog>

          <MyButton
            startIcon={<EditIcon fontSize="large" color='secondary' />}
            onClick={() => { props.onEdit(hospital); }}
          >
            Edit
          </MyButton>
        </Box>
      </Box>

      {isUnitOpen ? (
        <UnitModule
          hospital_name={hospital.hospital_name}
          onUpdate={props.onUpdate}
          onClose={() => { setIsUnitOpen(false); }}
        />
      ) : null}
    </Box>
  );
}

function Body() {
  const [isOpen, setIsOpen] = useState(false);
  const [hospitalList, setHospitalList] = useState([]);
  const [hospital, setHospital] = useState({});
  const [updated, setUpdated] = useState(0);

  const handleEdit = (hospital) => { setHospital(hospital); setIsOpen(true); };
  const handleClose = () => { setHospital({}); setIsOpen(false); };

  useEffect(() => {
    // fetch hospital data from database
    const data = { 'Action': 'get_hospitals', 'AuthEmail': localStorage.getItem('email') };
    post('/hospitals', data).then((result) => {
      const sorted_hospitals = result.sort((a, b) => a.hospital_name.localeCompare(b.hospital_name));
      setHospitalList(sorted_hospitals);
    });
  }, [updated]);

  const handleSubmit = (hospital) => {
    const data = {
      'Action': 'set_hospital',
      'AuthEmail': localStorage.getItem('email'),
      'HospitalName': hospital.hospital_name,
      'Review': hospital.review,
      'Timeslot': hospital.timeslot,
      'Frequency': hospital.frequency,
      'Units': hospital.units
    };
    post('/hospitals', data);
    handleClose();
    setUpdated(updated + 1);
  };

  const handleDelete = (hospital) => {
    const data = {
      'Action': 'remove_hospital',
      'AuthEmail': localStorage.getItem('email'),
      'HospitalName': hospital.hospital_name,
      'Review': hospital.review,
      'Timeslot': hospital.timeslot,
      'Frequency': hospital.frequency
    };
    post('/hospitals', data);
    setUpdated(updated + 1);
  };

  return (
    <Box sx={{ mt: 5 }}>
      <MyButton
        sx={{ mt: 2, ml: 5, mb: 5 }}
        onClick={() => { setIsOpen(true); }}>
        + Add Hospital
      </MyButton>
      <HospitalForm
        hospital={hospital}
        open={isOpen}
        onSubmit={handleSubmit}
        onClose={handleClose} />
      <Title />
      {hospitalList.map((hospital, i) => {
        return (
          <Box key={i}>
            <Divider />
            <Hospital
              hospital={hospital}
              onUpdate={() => { setUpdated(updated + 1) }}
              onDelete={handleDelete}
              onEdit={handleEdit} />
          </Box>);
      })}

    </Box>
  );
};


function HospitalModule() {
  return (
    <AccessManager>
      <Grid container>
        <Grid item xs={12}>
          <Header> Hospital Module </Header>
        </Grid>
        <Grid item xs={12}>
          <Body />
        </Grid>
      </Grid>
    </AccessManager>
  );
}

export default HospitalModule;
