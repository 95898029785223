import { Button } from '@mui/material';
import theme from './theme';

function MyButton(props) {
    const myProps = {...props};
    delete myProps.sx;
    // create default style for button
    const default_sx = {
        minWidth: '10%', 
        maxWidth: '100%', 
        fontWeight: 'bold',
        color: '#ffffff', 
        bgcolor: theme.palette.primary.main,
        '&:hover': { backgroundColor: theme.palette.secondary.main }
      };
    // append sx from props
    const new_sx = {...default_sx, ...[props.sx][0]};
    return(
        <Button 
          sx={ new_sx } 
          {...myProps}>
        { props.children }
        </Button> 
    );
};

export default MyButton;