import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import { theme, access_forbidden, post } from '../components';


function AccessManager(props) {
  const navigate = useNavigate();
  const [hasRole, setHasRole] = useState(true);

  useEffect(() => {
    if (localStorage.getItem('email') === null) {
      navigate('/');
    };
    let url = window.location.pathname;  // current page url
    if (props.url) {
      url = props.url;  // specifi url passed in property
    }
    const data = {
      'Action': 'has_role',
      'Url': url,
      'Email': localStorage.getItem('email') };
    post('/roles', data).then((role) => { setHasRole(role); });
  }, [navigate, props.url]);

  return (
    <ThemeProvider theme={theme}>
        { hasRole ? <>{ props.children }</> :
        <Box
          minWidth={'100%'}
          component='img'
          src={ access_forbidden }
          alt='access_forbidden'/> }
    </ThemeProvider>
  );
}

export default AccessManager;