import React from 'react';
import { Box, Grid, Chip } from '@mui/material';
import NotificationImportantIcon from '@mui/icons-material/NotificationImportant';
import { AuthButton } from '../authentication/Login';
import logo from './logo.svg';
import theme from './theme';


function Header(props) {
  return (
    <Grid container>
      <Grid item xs={2}>
        <Box
          component="img"
          src={logo}
          alt="logo"/>
      </Grid>
      <Grid item xs={7}>
        <Box sx={{ textAlign: 'center', typography: 'h1', color: theme.palette.primary.main, fontWeight: 'bold' }}>
          { props.children }
        </Box>
      </Grid>
      <Grid item xs={3}
        sx={{
          mt: 3,
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: 3
        }}>
        <Chip color='primary' icon={<NotificationImportantIcon />} label={props.total_notification} />
        <AuthButton />
      </Grid>
    </Grid>
  );
};

export default React.memo(Header);