import BACKEND_URL from '../env';

const post = async (path, data) => {
    // fetch and return response data in json format
    // path is internal apps api route
    // data is payload for post request
    const response = await fetch(`${BACKEND_URL}${path}`, {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(data)
    });
    // convert stream to string
    const result = await response.json();
    // return object 
    return JSON.parse(result);
};

export default post;