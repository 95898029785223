import React, { useEffect } from 'react';
import { GoogleLogin, googleLogout, GoogleOAuthProvider } from '@react-oauth/google';
import { ThemeProvider } from '@mui/material/styles';
import { Menu, MenuItem, ListItemIcon, Avatar, Chip, Box } from '@mui/material';
import { Container, Typography, CssBaseline, Divider } from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Logout } from '@mui/icons-material'
import { useNavigate } from 'react-router-dom';
import { theme, post } from '../components';
import jwt_decode from 'jwt-decode';


export function AuthButton() {
  const clientId = '678006799666-ufvg5lq19rcqkvtfpv8mrkcck9n2re4h.apps.googleusercontent.com';
  const navigate = useNavigate();

  useEffect(() => {
    if((localStorage.getItem('email')) && (window.location.pathname === '/')) {
      navigate('/roles');
    };
  },[navigate]);
  
  const onSuccess = (authResult) => {
    const decoded_token = jwt_decode(authResult.credential);
    // set local storage
    localStorage.setItem('email', decoded_token.email);
    localStorage.setItem('givenName', decoded_token.given_name);
    localStorage.setItem('familyName', decoded_token.family_name);
    localStorage.setItem('name', decoded_token.name);
    localStorage.setItem('picture', decoded_token.picture);
    // redirect to roles
    if(window.location.pathname === '/') {
      navigate('/roles');
    };
  };

  const onFailure = (err) => {
    console.log('failed', err);
    navigate('/');
  };

  const logOut = () => {
    // trinera user should also release tasks when loged out
    const data = {
      'Action': 'remove_tasks',
      'Message': `Reviewer [${localStorage.getItem('email')}] logged out.`,
      'Email': localStorage.getItem('email')};
    post('/trinetra', data);
    localStorage.clear(); 
    navigate('/');
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <GoogleOAuthProvider clientId={clientId}>
      { localStorage.getItem('email') ? (
        <div>
          <Chip
            color='primary'
            avatar={<Avatar alt='user image' src={localStorage.getItem('picture')} />}
            label={localStorage.getItem('email')}
            variant='outlined'
            component='button' onClick={handleClick}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup='true'
            aria-expanded={open ? 'true' : undefined} />
          <Menu
            anchorEl={anchorEl}
            id='account-menu'
            open={open}
            onClose={handleClose}
            onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: 1.5,
                '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
                },
                '&:before': {
                content: '""',
                display: 'block',
                position: 'absolute',
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: 'background.paper',
                transform: 'translateY(-50%) rotate(45deg)',
                zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }} >
            <div onClick={()=>{navigate('/roles');}}>
              <MenuItem>
                <Avatar color='disabled' />
                Home
              </MenuItem>
            </div>
            <Divider />
            <div onClick={() => {googleLogout(); logOut();}}>
              <MenuItem>
                <ListItemIcon>
                  <Logout fontSize='small' />
                </ListItemIcon>
                Logout
              </MenuItem>
            </div>
          </Menu>
        </div>
      ) : (
        // <Button onClick={onSuccess}>Login</Button>
        <GoogleLogin
          onSuccess={onSuccess}
          onFailure={onFailure} />
        )}
    </GoogleOAuthProvider>
  );
}


function Login() {
  return (
    <ThemeProvider theme={theme}>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
          mt: 20,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center'}}>
          <Avatar>
            <LockOutlinedIcon />
          </Avatar>
          <Typography variant='h4' sx={{ mt: 3 }}>
            Sign in
          </Typography>
          <Box sx={{ mt: 3 }}>
            <AuthButton />
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default Login;